<template>
  <v-card :loading="isLoading">
    <v-card-title>
      RoboSAY POS İşlem Detayı
      <v-col class="text-right p-0" v-if="originalData.id">
        <rs-action
          @click="markAsManuallyVerified"
          v-if="
            hasRole('superadministrator') &&
            !originalData.success_at !== null &&
            originalData.manually_verified_at === null
          "
        >
          Elle Kontrol Edilmiş Olarak İşaretle
        </rs-action>
        <rs-action
          @click="removeManualVerification"
          v-if="
            hasRole('superadministrator') &&
            !originalData.success_at !== null &&
            originalData.manually_verified_at !== null
          "
        >
          Elle Kontrol Etmeyi İptal Et
        </rs-action>
      </v-col>
    </v-card-title>
    <v-card-text v-if="originalData.id">
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
      <v-form>
        <v-row dense>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Sakin"
              :value="originalData.resident.name"
              readonly
            />
            <router-link
              v-if="can('see-house-income')"
              :to="{
                name: 'incomes.residents.show',
                params: { id: originalData.resident_id },
              }"
            >
              Sakin Mali İşlemler
            </router-link>
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Bağımsız bölüm"
              :value="
                originalData.house_id ? originalData.house.door_number : ''
              "
              readonly
            />
            <router-link
              v-if="originalData.house_id && can('see-house-income')"
              :to="{
                name: 'incomes.houses.show',
                params: { id: originalData.house_id },
              }"
            >
              BB Mali İşlemler
            </router-link>
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Tarih"
              :value="new Date(originalData.created_at).toLocaleString()"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-money-field
              :value="originalData.amount"
              :disabled="true"
              label="Kişi Tutar"
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-money-field
              :value="originalData.amount_for_cluster"
              :disabled="true"
              label="TYA Tutar"
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Durum"
              :value="originalData.success_at ? 'Başarılı' : 'Başarısız'"
              readonly
            />
          </v-col>
        </v-row>
      </v-form>

      <v-row dense>
        <v-col cols="12"><v-divider /></v-col>
        <v-col sm="6" v-if="originalData.payment_collectings.length">
          <h5>Oluşturulan Tahsilatlar</h5>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Bağımsız Bölüm</th>
                  <th>Tutar</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="c in originalData.payment_collectings" :key="c.id">
                  <td>
                    {{ c.collecting.house.block.name }}-{{
                      c.collecting.house.door_number
                    }}
                  </td>
                  <td class="text-end">
                    <a
                      href="#"
                      @click.prevent="
                        $refs.collectingDetail.show(c.collecting_id)
                      "
                    >
                      <rs-table-cell-number
                        price
                        :value="c.collecting.amount"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col sm="6" v-if="originalData.payment_debts.length">
          <h5>Seçilen Borçlar</h5>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Bağımsız Bölüm</th>
                  <th>Açıklama</th>
                  <th>Borç Tutar</th>
                  <th>Ödenen Tutar</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="d in originalData.payment_debts" :key="d.id">
                  <td>
                    {{ d.debt.house_assessment.house.block.name }}-{{
                      d.debt.house_assessment.house.door_number
                    }}
                  </td>
                  <td>{{ d.debt.house_assessment.description }}</td>
                  <td class="text-end">
                    <a
                      href="#"
                      @click.prevent="$refs.debtDetail.show(d.debt_id)"
                    >
                      <rs-table-cell-number
                        price
                        :value="d.debt.amount + d.debt.deferment_amount"
                      />
                    </a>
                  </td>
                  <td class="text-end">
                    <rs-table-cell-number
                      price
                      :value="
                        originalData.success_at ? d.amount_for_cluster : 0.0
                      "
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col sm="12" v-if="hasRole('superadministrator')">
          <rs-action @click="loadPaymentProviderData()" :loading="isLoading">
            Ödeme Kuruluşundan Sorgula
          </rs-action>

          <pre v-if="providerResponse !== null">
            <p v-for="(value, key) in providerResponse" :key="key">
              <strong>{{ key }}:</strong>
              {{ value }}
            </p>
          </pre>
        </v-col>
      </v-row>
      <CollectingDetail ref="collectingDetail" />
      <DebtDetail ref="debtDetail" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { hasForm, hasPermissions } from "@/view/mixins";
import { CollectingDetail, DebtDetail } from "@/view/pages/income/forms";
import { isPage } from "../../../mixins";

export default {
  name: "CCPaymentDetail",
  mixins: [hasForm, hasPermissions, isPage],
  props: {
    /**
     * Bank Activity ID
     * @type {Number}
     */
    id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    id(newValue) {
      if (newValue) {
        this.load();
      }
    },
  },
  components: {
    CollectingDetail,
    DebtDetail,
  },
  data() {
    return {
      pageMeta: {
        title: "POS İşlemleri",
      },
      providerResponse: null,
    };
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => this.load());
    }
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get("cc-payments", this.id)
        .then((response) => {
          this.updateClusterId(response.data.data.resident.cluster_id);
          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    loadPaymentProviderData() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .query(`cc-payments/${this.id}/provider-data`)
        .then((response) => {
          this.providerResponse = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    markAsManuallyVerified() {
      if (this.isLoading || !this.id || this.success_at === null) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`admin/cc-payments/${this.id}/manually-verify`)
        .then(() => {
          this.originalData.manually_verified_at = new Date();
          this.$toast.success("Başarılı");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    removeManualVerification() {
      if (this.isLoading || !this.id || this.success_at === null) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`admin/cc-payments/${this.id}/remove-manual-verification`)
        .then(() => {
          this.originalData.manually_verified_at = null;
          this.$toast.success("Başarılı");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
